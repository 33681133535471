<script>
import compact from 'lodash/compact';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import intersectionBy from 'lodash/intersectionBy';
import map from 'lodash/map';
import without from 'lodash/without';

import { mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import carRental from '../../store/CarrentalModuleMap';
export default {
  name: 'AgentComponent',
  components: {
    MuiAlgoliaSelect,
    MuiInputText,
    MuiValidationWrapper,
  },
  directives: {
    Validate,
  },
  props: {
    agent: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      locationsLimit: 10,
      opertatorList: [],
      selectedOperators: [],
      selectedRoles: [],
      payload: {
        firstName: '',
        lastName: '',
        email: '',
        mainOperatorUuid: null,
        locations: [],
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
      isCarRental: state => get(state, 'operators.active.is_carrental'),
    }),
    ...mapState(carRental.agents, {
      stations: state => get(state, 'stations.data', []),
      edit: state => get(state, 'edit', {}),
    }),
    options() {
      return map(this.stations, item => ({
        value: item.id,
        label: item.name,
      }));
    },
    isEditing() {
      return !isEmpty(this.edit);
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
  },
  async mounted() {
    const options = { hitsPerPage: 500 };
    const { hits } = await this.$algolia.fetchIndex('cs_operators', options);
    this.opertatorList = hits;
    this.selectedRoles = map(this.edit.selectedRoles, 'name') || [];
    this.selectedOperators = map(this.edit.operators, 'id') || [];
    this.$watch(vm => [
      vm.selectedOperators,
      vm.selectedRoles,
      vm.payload.firstName,
      vm.payloadlastName,
      vm.payload.email,
    ], () => {
      const operatorIds = map(this.selectedOperators, operatorId => ({ id: operatorId }));
      const operators = map(intersectionBy(hits, operatorIds, 'id'), 'uuid');

      const roles = compact(this.selectedRoles);
      const payload = {
        ...this.payload,
        ...{
          operators,
          roles,
        },
      };
      this.$emit('add-agent', payload);
    });

    this.payload.mainOperatorUuid = this.activeOperatorUuid;

    if (this.isEditing) {
      this.payload.firstName = this.edit.firstName;
      this.payload.lastName = this.edit.lastName;
      this.payload.email = this.edit.email;
      this.payload.agentUuid = this.edit.agentUuid;
    }
  },
  methods: {
    sentenceCase,
    removeOperator(item) {
      this.selectedOperators = without(this.selectedOperators, item);
    },
    removeRole(item) {
      this.selectedRoles = without(this.selectedRoles, item);
    },
    getOperatorName(operator) {
      return get(find(this.opertatorList, ['id', operator]), 'name');
    },
  },
};
</script>

<template>
  <div class="AgentComponent px-3 py-5">
    <MuiValidationWrapper ref="form">
      <div class="row">
        <div class="col-4">
          <p class="pb-2 emobg-body-2">
            Name
          </p>
          <MuiInputText
            v-model="payload.firstName"
            v-validate="{
              isRequired: true,
            }"
            :disabled="isEditing"
            name="firstName"
            placeholder="First name"
          />
        </div>
        <div class="col-4">
          <p class="pb-2 emobg-body-2">
            Last name
          </p>
          <MuiInputText
            v-model="payload.lastName"
            v-validate="{
              isRequired: true,
            }"
            :disabled="isEditing"
            name="lastName"
            placeholder="Last name"
          />
        </div>
        <div class="col-4">
          <p class="pb-2 emobg-body-2">
            {{ `Email ${ isEditing ? '': 'to send invitation'}` }}
          </p>
          <MuiInputText
            v-model="payload.email"
            v-validate="{
              isRequired: true,
              isEmail: true,
            }"
            :type="INPUT_TYPES.email"
            :disabled="isEditing"
            name="email"
            placeholder="E-mail"
          />
        </div>
      </div>
    </MuiValidationWrapper>
    <div class="row">
      <div
        v-if="!isCarRental"
        class="col-12 mt-2"
      >
        <p :class="['pb-2 emobg-body-2', {'disabled' : isEditing}]">
          Main operator
        </p>
        <MuiAlgoliaSelect
          v-model="payload.mainOperatorUuid"
          :index="ALGOLIA_INDEXES.csOperators"
          :title="result => result.name"
          :object-label="operator => operator.name"
          placeholder="Select main operator"
          path-value="uuid"
          name="main"
          class="w-100"
          no-cache
        />
      </div>
      <div class="col-6 my-3">
        <p class="pb-2 emobg-body-2">
          Roles
        </p>
        <MuiAlgoliaSelect
          v-model="selectedRoles"
          :index="ALGOLIA_INDEXES.roles"
          :title="result => result.display_name"
          filters="group:backoffice"
          placeholder="Select roles"
          name="role"
          no-cache
          path-value="name"
          class="w-100 pb-2"
          multiple
        />
        <template v-for="(role, index) in selectedRoles">
          <ui-badge
            :key="`role-${index}`"
            :color="GRAYSCALE.ground"
            class="mr-2"
          >
            <span class="emobg-color-black mr-2">
              {{ sentenceCase(role) }}
            </span>
            <em
              class="emobg-color-black icons-remove-filled cursor-pointer"
              @click="removeRole(role)"
            />
          </ui-badge>
        </template>
      </div>

      <div class="col-6 my-3">
        <p class="pb-2 emobg-body-2">
          Operators
        </p>
        <MuiAlgoliaSelect
          v-model="selectedOperators"
          :index="ALGOLIA_INDEXES.csOperators"
          :title="result => result.name"
          placeholder="Select operators"
          name="operator"
          no-cache
          path-value="id"
          class="w-100 pb-2"
          multiple
        />
        <template v-for="(operator, index) in selectedOperators">
          <ui-badge
            :key="`operator-${index}`"
            :color="GRAYSCALE.ground"
            class="mr-2"
          >
            <span class="emobg-color-black mr-2">
              {{ getOperatorName(operator) }}
            </span>
            <em
              class="emobg-color-black icons-remove-filled cursor-pointer"
              @click="removeOperator(operator)"
            />
          </ui-badge>
        </template>
      </div>
    </div>
  </div>
</template>
