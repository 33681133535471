var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AgentsView px-2 px-lg-5" },
    [
      _c("h1", { staticClass: "pb-4 d-flex justify-content-between" }, [
        _vm._v(" Agents "),
        _c(
          "div",
          [
            _vm.includes(
              _vm.permissions,
              _vm.CARRENTAL_PERMISSIONS.agentsImport
            ) && _vm.features[_vm.CARRENTAL_FLAGS.importAgents]
              ? _c("ui-button", { on: { clickbutton: _vm.importAgents } }, [
                  _vm._v(" Import list "),
                ])
              : _vm._e(),
            _vm.includes(_vm.permissions, _vm.CARRENTAL_PERMISSIONS.agentsAdd)
              ? _c(
                  "ui-button",
                  { staticClass: "ml-3", on: { clickbutton: _vm.newAgent } },
                  [_vm._v(" New agent ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("MuiAlgoliaList", {
        ref: "agents",
        attrs: {
          facets: _vm.isCarRental ? _vm.carrentalFacets : _vm.facets,
          "table-config": _vm.contentCells,
          "export-columns": _vm.isCarRental
            ? _vm.carrentalExportColumns
            : _vm.contentCells,
          "no-data-label": _vm.FALLBACK_MESSAGE.dash,
          filters: _vm.isCarRental
            ? `cs_operators.id: ${_vm.activeOperatorId}`
            : "",
          index: _vm.ALGOLIA_INDEXES.agents,
          "is-export-enabled": "",
        },
      }),
      _c(
        "MuiModal",
        _vm._b(
          {
            staticClass: "AgentsView__new-agent",
            attrs: { "data-test-id": "agent-modal" },
            model: {
              value: _vm.isModalVisible,
              callback: function ($$v) {
                _vm.isModalVisible = $$v
              },
              expression: "isModalVisible",
            },
          },
          "MuiModal",
          _vm.modalConfig,
          false
        ),
        [
          _c("AgentComponent", {
            attrs: { slot: "body", user: "" },
            on: { "add-agent": _vm.updateAgentPayload },
            slot: "body",
          }),
          _c(
            "div",
            {
              staticClass: "p-3 d-flex justify-content-end",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "ui-button",
                {
                  staticClass: "emobg-color-white-contrast mr-3",
                  attrs: { color: _vm.GRAYSCALE.white },
                  on: { clickbutton: _vm.toggleModal },
                },
                [_vm._v(" Cancel ")]
              ),
              !_vm.isImport
                ? _c(
                    "ui-button",
                    {
                      attrs: {
                        loading: _vm.isAgentCreating || _vm.isAgentUpdating,
                        disabled: !_vm.isAgentValid,
                      },
                      on: {
                        clickbutton: function ($event) {
                          return _vm.createAgent(_vm.agent)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(`${_vm.isEditing ? "Apply changes" : "Add"}`) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isImport && !_vm.importErrors.length
                ? _c(
                    "ui-button",
                    {
                      attrs: {
                        loading: _vm.isImportLoading,
                        disabled: !_vm.isImportValid,
                      },
                      on: {
                        clickbutton: function ($event) {
                          return _vm.confirmImport()
                        },
                      },
                    },
                    [_vm._v(" Confirm import ")]
                  )
                : _vm._e(),
              _vm.importErrors.length
                ? _c("ui-button", { on: { clickbutton: _vm.toggleModal } }, [
                    _vm._v(" Close "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "MuiModal",
        _vm._b(
          {
            attrs: { "data-test-id": "new-agent-modal-success" },
            model: {
              value: _vm.isSuccessModalVisible,
              callback: function ($$v) {
                _vm.isSuccessModalVisible = $$v
              },
              expression: "isSuccessModalVisible",
            },
          },
          "MuiModal",
          _vm.successModalConfig,
          false
        ),
        [
          _c(
            "div",
            {
              staticClass: "py-3 emobg-color-ink-light",
              attrs: { slot: "body" },
              slot: "body",
            },
            [
              _c("p", [_vm._v("An invitation has been send to:")]),
              _c("p", { staticClass: "py-3 emobg-color-black" }, [
                _vm._v(" " + _vm._s(_vm.agent.email) + " "),
              ]),
              _c("p", [
                _vm._v(
                  "The new agent has to confirm the invitation and set up a password to complete the process."
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "p-3 d-flex justify-content-end",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "ui-button",
                {
                  on: {
                    clickbutton: function ($event) {
                      _vm.isSuccessModalVisible = false
                    },
                  },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }