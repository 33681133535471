<script>
import concat from 'lodash/concat';
import compact from 'lodash/compact';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapMutations, mapState } from 'vuex';
import { TIME_ZONE } from '@emobg/web-utils';
import { MuiAlgoliaList, MuiModal } from '@emobg/motion-ui/v1';
import permissionsMixin from '@domains/Main/mixins/permissionsMixin';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { refreshAlgoliaStore } from '@/utils';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import AgentComponent from './components/AgentComponent.vue';
import {
  carrentalExportColumns,
  carrentalFacets,
  contentCells,
  facets,
} from './config';
import carRental from '../store/CarrentalModuleMap';
import { CARRENTAL_PERMISSIONS } from '../const/permissions';
import { CARRENTAL_FLAGS } from '../const/features';

export default {
  name: 'AgentsView',
  components: {
    AgentComponent,
    MuiModal,
    MuiAlgoliaList,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      isModalVisible: false,
      isAgentUpdating: false,
      modalConfig: {
        title: 'Carrental agent',
        header: {
          class: 'pl-3',
          isClosable: true,
        },
      },
      isSuccessModalVisible: false,
      successModalConfig: {
        title: 'Agent added to backoffice',
        header: {
          class: 'pl-3',
          isClosable: true,
        },
      },
      isAgentValid: false,
      isImport: false,
      isEdit: false,
      agent: {},
      payload: {},
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
      activeOperatorId: state => get(state, 'operators.active.id'),
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
      features: state => get(state, 'features.data'),
      isCarRental: state => get(state, 'operators.active.is_carrental'),
    }),
    ...mapState(carRental.agents, {
      edit: state => state.edit,
      remove: state => state.remove,
      importErrors: state => state.data.errors || [],
      importSuccess: state => state.data.success || [],
      isImportLoading: state => state.STATUS.LOADING,
      isImportLoaded: state => state.STATUS.LOADED,
      isAgentCreating: state => state.create.STATUS.LOADING,
      isAgentCreated: state => state.create.STATUS.LOADED,
    }),
    isImportValid() {
      return this.payload.locations && this.payload.operators && this.payload.agents_file;
    },
    isEditing() {
      return !isEmpty(this.edit);
    },
  },
  watch: {
    edit(newValue) {
      if (!isEmpty(newValue)) {
        this.isModalVisible = true;
      }
    },
    remove(newValue) {
      if (newValue) {
        this.refreshAlgoliaStore(this.$refs.agents);
      }
    },
    isAgentCreated(newValue) {
      if (newValue) {
        this.toggleModal();
        this.isSuccessModalVisible = true;
      }
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.CARRENTAL_PERMISSIONS = CARRENTAL_PERMISSIONS;
    this.CARRENTAL_FLAGS = CARRENTAL_FLAGS;
    this.contentCells = contentCells;
    this.facets = facets;
    this.carrentalFacets = carrentalFacets;
    this.carrentalExportColumns = carrentalExportColumns({ operatorTimezone: this.operatorTimezone });
  },
  methods: {
    refreshAlgoliaStore,
    ...mapActions(carRental.agents, [
      'postCreateAgent',
      'postImportAgents',
      'putAgentRoles',
      'putAgentOperators',
    ]),
    ...mapMutations(carRental.agents, [
      'clearEditAgent',
      'clearImportAgent',
    ]),
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    newAgent() {
      this.clearEditAgent();
      this.isModalVisible = true;
    },
    async createAgent(agent) {
      if (this.isEditing) {
        this.isAgentUpdating = true;
        await this.putAgentRoles({ agentUuid: this.agent.agentUuid,
          data: { roles: concat(compact(agent.roles)) } });

        await this.putAgentOperators({ agentUuid: this.agent.agentUuid,
          data: { operator_uuids: concat(compact(agent.operatorUuids)) } })
          .then(() => {
            this.clearEditAgent();
            this.isModalVisible = false;
            this.isAgentUpdating = false;
          });
      } else {
        this.postCreateAgent(agent);
      }
      this.refreshAlgoliaStore(this.$refs.agents);
    },
    updateAgentPayload(payload) {
      const {
        firstName, lastName, email, operators: operatorUuids, mainOperatorUuid, roles, locations, agentUuid,
      } = payload;
      this.agent = { firstName, lastName, email, roles, operatorUuids, mainOperatorUuid, locations, agentUuid };
      this.isAgentValid = this.agent.firstName && this.agent.lastName && this.agent.email && this.agent.mainOperatorUuid;
    },
    importAgents() {
      this.isImport = true;
      this.payload = {};
      this.clearImportAgent();
      this.toggleModal();
    },
    updatePayload(payload) {
      this.payload = payload;
    },
    confirmImport() {
      this.postImportAgents(this.payload);
    },
    includes,
  },
};
</script>

<template>
  <div class="AgentsView px-2 px-lg-5">
    <h1 class="pb-4 d-flex justify-content-between">
      Agents
      <div>
        <ui-button
          v-if="includes(permissions, CARRENTAL_PERMISSIONS.agentsImport) && features[CARRENTAL_FLAGS.importAgents]"
          @clickbutton="importAgents"
        >
          Import list
        </ui-button>
        <ui-button
          v-if="includes(permissions, CARRENTAL_PERMISSIONS.agentsAdd)"
          class="ml-3"
          @clickbutton="newAgent"
        >
          New agent
        </ui-button>
      </div>
    </h1>
    <MuiAlgoliaList
      ref="agents"
      :facets="isCarRental ? carrentalFacets : facets"
      :table-config="contentCells"
      :export-columns="isCarRental ? carrentalExportColumns : contentCells"
      :no-data-label="FALLBACK_MESSAGE.dash"
      :filters="isCarRental ? `cs_operators.id: ${activeOperatorId}` : ''"
      :index="ALGOLIA_INDEXES.agents"
      is-export-enabled
    />

    <MuiModal
      v-model="isModalVisible"
      data-test-id="agent-modal"
      v-bind="modalConfig"
      class="AgentsView__new-agent"
    >
      <AgentComponent
        slot="body"
        user=""
        @add-agent="updateAgentPayload"
      />
      <div
        slot="footer"
        class="p-3 d-flex justify-content-end"
      >
        <ui-button
          :color="GRAYSCALE.white"
          class="emobg-color-white-contrast mr-3"
          @clickbutton="toggleModal"
        >
          Cancel
        </ui-button>
        <ui-button
          v-if="!isImport"
          :loading="isAgentCreating || isAgentUpdating"
          :disabled="!isAgentValid"
          @clickbutton="createAgent(agent)"
        >
          {{ `${isEditing ? 'Apply changes': 'Add'}` }}
        </ui-button>
        <ui-button
          v-if="isImport && !importErrors.length"
          :loading="isImportLoading"
          :disabled="!isImportValid"
          @clickbutton="confirmImport()"
        >
          Confirm import
        </ui-button>
        <ui-button
          v-if="importErrors.length"
          @clickbutton="toggleModal"
        >
          Close
        </ui-button>
      </div>
    </MuiModal>

    <MuiModal
      v-model="isSuccessModalVisible"
      data-test-id="new-agent-modal-success"
      v-bind="successModalConfig"
    >
      <div
        slot="body"
        class="py-3 emobg-color-ink-light"
      >
        <p>An invitation has been send to:</p>
        <p class="py-3 emobg-color-black">
          {{ agent.email }}
        </p>
        <p>The new agent has to confirm the invitation and set up a password to complete the process.</p>
      </div>
      <div
        slot="footer"
        class="p-3 d-flex justify-content-end"
      >
        <ui-button @clickbutton="isSuccessModalVisible = false">
          OK
        </ui-button>
      </div>
    </MuiModal>
  </div>
</template>
