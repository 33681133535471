var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AgentComponent px-3 py-5" },
    [
      _c("MuiValidationWrapper", { ref: "form" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("p", { staticClass: "pb-2 emobg-body-2" }, [_vm._v(" Name ")]),
              _c("MuiInputText", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n            isRequired: true,\n          }",
                  },
                ],
                attrs: {
                  disabled: _vm.isEditing,
                  name: "firstName",
                  placeholder: "First name",
                },
                model: {
                  value: _vm.payload.firstName,
                  callback: function ($$v) {
                    _vm.$set(_vm.payload, "firstName", $$v)
                  },
                  expression: "payload.firstName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("p", { staticClass: "pb-2 emobg-body-2" }, [
                _vm._v(" Last name "),
              ]),
              _c("MuiInputText", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n            isRequired: true,\n          }",
                  },
                ],
                attrs: {
                  disabled: _vm.isEditing,
                  name: "lastName",
                  placeholder: "Last name",
                },
                model: {
                  value: _vm.payload.lastName,
                  callback: function ($$v) {
                    _vm.$set(_vm.payload, "lastName", $$v)
                  },
                  expression: "payload.lastName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("p", { staticClass: "pb-2 emobg-body-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      `Email ${_vm.isEditing ? "" : "to send invitation"}`
                    ) +
                    " "
                ),
              ]),
              _c("MuiInputText", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                      isEmail: true,
                    },
                    expression:
                      "{\n            isRequired: true,\n            isEmail: true,\n          }",
                  },
                ],
                attrs: {
                  type: _vm.INPUT_TYPES.email,
                  disabled: _vm.isEditing,
                  name: "email",
                  placeholder: "E-mail",
                },
                model: {
                  value: _vm.payload.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.payload, "email", $$v)
                  },
                  expression: "payload.email",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        !_vm.isCarRental
          ? _c(
              "div",
              { staticClass: "col-12 mt-2" },
              [
                _c(
                  "p",
                  { class: ["pb-2 emobg-body-2", { disabled: _vm.isEditing }] },
                  [_vm._v(" Main operator ")]
                ),
                _c("MuiAlgoliaSelect", {
                  staticClass: "w-100",
                  attrs: {
                    index: _vm.ALGOLIA_INDEXES.csOperators,
                    title: (result) => result.name,
                    "object-label": (operator) => operator.name,
                    placeholder: "Select main operator",
                    "path-value": "uuid",
                    name: "main",
                    "no-cache": "",
                  },
                  model: {
                    value: _vm.payload.mainOperatorUuid,
                    callback: function ($$v) {
                      _vm.$set(_vm.payload, "mainOperatorUuid", $$v)
                    },
                    expression: "payload.mainOperatorUuid",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-6 my-3" },
          [
            _c("p", { staticClass: "pb-2 emobg-body-2" }, [_vm._v(" Roles ")]),
            _c("MuiAlgoliaSelect", {
              staticClass: "w-100 pb-2",
              attrs: {
                index: _vm.ALGOLIA_INDEXES.roles,
                title: (result) => result.display_name,
                filters: "group:backoffice",
                placeholder: "Select roles",
                name: "role",
                "no-cache": "",
                "path-value": "name",
                multiple: "",
              },
              model: {
                value: _vm.selectedRoles,
                callback: function ($$v) {
                  _vm.selectedRoles = $$v
                },
                expression: "selectedRoles",
              },
            }),
            _vm._l(_vm.selectedRoles, function (role, index) {
              return [
                _c(
                  "ui-badge",
                  {
                    key: `role-${index}`,
                    staticClass: "mr-2",
                    attrs: { color: _vm.GRAYSCALE.ground },
                  },
                  [
                    _c("span", { staticClass: "emobg-color-black mr-2" }, [
                      _vm._v(" " + _vm._s(_vm.sentenceCase(role)) + " "),
                    ]),
                    _c("em", {
                      staticClass:
                        "emobg-color-black icons-remove-filled cursor-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.removeRole(role)
                        },
                      },
                    }),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "col-6 my-3" },
          [
            _c("p", { staticClass: "pb-2 emobg-body-2" }, [
              _vm._v(" Operators "),
            ]),
            _c("MuiAlgoliaSelect", {
              staticClass: "w-100 pb-2",
              attrs: {
                index: _vm.ALGOLIA_INDEXES.csOperators,
                title: (result) => result.name,
                placeholder: "Select operators",
                name: "operator",
                "no-cache": "",
                "path-value": "id",
                multiple: "",
              },
              model: {
                value: _vm.selectedOperators,
                callback: function ($$v) {
                  _vm.selectedOperators = $$v
                },
                expression: "selectedOperators",
              },
            }),
            _vm._l(_vm.selectedOperators, function (operator, index) {
              return [
                _c(
                  "ui-badge",
                  {
                    key: `operator-${index}`,
                    staticClass: "mr-2",
                    attrs: { color: _vm.GRAYSCALE.ground },
                  },
                  [
                    _c("span", { staticClass: "emobg-color-black mr-2" }, [
                      _vm._v(" " + _vm._s(_vm.getOperatorName(operator)) + " "),
                    ]),
                    _c("em", {
                      staticClass:
                        "emobg-color-black icons-remove-filled cursor-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.removeOperator(operator)
                        },
                      },
                    }),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }