import map from 'lodash/map';
import {
  DATE_FORMAT,
  TIME_ZONE,
  formatUtc,
} from '@emobg/web-utils';
import { ALGOLIA_REFINEMENT_OPTIONS } from '@emobg/motion-ui/v1';

import ItemListComponent from '@domains/Carrental/AlertsTrigger/List/components/ItemListComponent';
import AgentActions from '../components/AgentActions.vue';

const contentCells = [
  {
    title: 'Agent',
    property: 'user',
    width: 200,
    transformValue: user => `${user.first_name} ${user.last_name}`,
  },
  {
    title: 'Email',
    property: 'user.email',
    width: 300,
  },
  {
    title: 'Role',
    property: 'user.roles',
    component: ItemListComponent,
    props: result => ({
      items: map(result.user.roles, 'display_name'),
      noItemsText: '-',
      count: 1,
    }),
    width: 250,
    transformValue: roles => map(roles, 'display_name').join(' - '),
  },
  {
    title: 'Operators',
    property: 'cs_operators',
    component: ItemListComponent,
    props: result => ({
      items: map(result.cs_operators, 'name'),
      count: 2,
      noItemsText: '-',
    }),
    width: 300,
    transformValue: csOperators => map(csOperators, 'name').join(' - '),
  },
  {
    title: '',
    component: AgentActions,
    width: 50,
    props: result => ({ result }),
  },
];

const carrentalExportColumns = ({ operatorTimezone = TIME_ZONE.default }) => [
  ...contentCells,
  {
    title: 'Created at',
    property: 'created_at',
    width: 150,
    transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
  },
];

const facets = [
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Roles',
      attributeName: 'user.roles.display_name',
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Operators',
      attributeName: 'cs_operators.name',
    },
  },
];

const carrentalFacets = [
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: 'Carrental roles',
      attributeName: 'user.roles.display_name',
    },
  },
];

export {
  contentCells,
  carrentalFacets,
  facets,
  carrentalExportColumns
};
