<script>
export default {
  name: 'ItemListComponent',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 2,
    },
    noItemsText: {
      type: String,
      default: 'All',
    },
  },
  computed: {
    itemsList() {
      return this.items.slice(0, this.count).join(', ') || this.noItemsText;
    },
    itemsString() {
      return this.items.join(', ');
    },
    additionalInfo() {
      return (this.items.length > this.count) ? `(+ ${this.items.length - this.count} more)` : null;
    },
  },
};
</script>

<template>
  <div>
    <div data-test-id="item_list">
      {{ itemsList }}
      <ui-tooltip
        v-if="additionalInfo"
        :key="itemsString"
        :tooltip="itemsString"
      >
        {{ additionalInfo }}
      </ui-tooltip>
    </div>
  </div>
</template>
