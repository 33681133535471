<script>
import includes from 'lodash/includes';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import { mapActions, mapMutations } from 'vuex';
import { MuiModal } from '@emobg/motion-ui/v1';

import permissionsMixin from '@domains/Main/mixins/permissionsMixin';

import { CARRENTAL_PERMISSIONS } from '../../const/permissions';

import carRental from '../../store/CarrentalModuleMap';
export default {
  name: 'AgentActions',
  components: { MuiModal },
  mixins: [permissionsMixin],
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isModalOpen: false,
      modalConfig: {
        title: 'Remove agent',
        header: {
          class: 'pl-3',
        },
      },
    };
  },
  computed: {
    editPayload() {
      /* eslint-disable camelcase */
      const {
        first_name, last_name, email, roles,
      } = this.result.user;
      const { cs_operators: operatorNames } = this.result;
      return { first_name, last_name, email, roles, operatorNames };
    },
  },
  created() {
    const editAction = includes(this.permissions, CARRENTAL_PERMISSIONS.agentsEdit)
      ? {
        label: 'Edit',
        action: () => this.updateAgent(),
      }
      : null;

    const removeAction = includes(this.permissions, CARRENTAL_PERMISSIONS.agentsRemove)
      ? {
        label: 'Remove',
        action: () => this.removeAgent(),
      }
      : null;

    this.dropdownActions = compact(concat(editAction, removeAction));
  },
  methods: {
    ...mapMutations(carRental.agents, [
      'setEditAgent',
      'setRemoveAgent',
    ]),
    ...mapActions(carRental.agents, [
      'deleteAgent',
    ]),
    updateAgent() {
      const { cs_operators: operators, user, uuid } = this.result;
      const {
        email, first_name: firstName, last_name: lastName, roles: selectedRoles,
      } = user;
      const agent = { operators, email, firstName, lastName, selectedRoles, agentUuid: uuid };

      this.setEditAgent(agent);
    },
    removeAgent() {
      this.isModalOpen = true;
    },
    async confirmAgentRemoval() {
      await this.deleteAgent(this.result.uuid);
      this.setRemoveAgent(this.result.uuid);
      this.isModalOpen = false;
    },
  },
};
</script>

<template>
  <div class="AgentActions float-right">
    <ui-dropdown
      :color="GRAYSCALE.white"
      :placement="PLACEMENTS.left"
    >
      <ui-button
        slot="trigger"
        :color="GRAYSCALE.white"
        contrast
        square
      >
        <ui-icon
          :size="ICONS_SIZES.small"
          :color="GRAYSCALE.ink"
          :icon="ICONS.optionsHorizontalFull"
        />
      </ui-button>
      <ui-dropdown-actions
        slot="content"
        :actions.prop="dropdownActions"
      />
    </ui-dropdown>
    <MuiModal
      v-model="isModalOpen"
      data-test-id="list-cell-delete-alert-by-email-modal"
      v-bind="modalConfig"
      @close-modal="isModalOpen = false"
    >
      <div
        slot="body"
        data-test-id="list-cell-delete-feedback"
        class="py-3 emobg-font-weight-light emobg-color-ink-light"
      >
        Are you sure you want to remove full backoffice access for:
        <p class="pt-3">
          {{ result.user.first_name }} {{ result.user.last_name }} -
        </p>
        <p class="emobg-color-ink">
          {{ result.user.email }}
        </p>
      </div>
      <div
        slot="footer"
        class="d-flex justify-content-end p-3"
      >
        <ui-button
          data-test-id="list-cell-modal-cancel"
          :color="GRAYSCALE.white"
          class="emobg-color-white-contrast mr-2"
          @clickbutton="isModalOpen = false"
        >
          Cancel
        </ui-button>
        <ui-button
          data-test-id="list-cell-modal-confirm"
          :color="COLORS.danger"
          @clickbutton="confirmAgentRemoval()"
        >
          Remove access
        </ui-button>
      </div>
    </MuiModal>
  </div>
</template>
