var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AgentActions float-right" },
    [
      _c(
        "ui-dropdown",
        {
          attrs: { color: _vm.GRAYSCALE.white, placement: _vm.PLACEMENTS.left },
        },
        [
          _c(
            "ui-button",
            {
              attrs: {
                slot: "trigger",
                color: _vm.GRAYSCALE.white,
                contrast: "",
                square: "",
              },
              slot: "trigger",
            },
            [
              _c("ui-icon", {
                attrs: {
                  size: _vm.ICONS_SIZES.small,
                  color: _vm.GRAYSCALE.ink,
                  icon: _vm.ICONS.optionsHorizontalFull,
                },
              }),
            ],
            1
          ),
          _c("ui-dropdown-actions", {
            attrs: { slot: "content" },
            domProps: { actions: _vm.dropdownActions },
            slot: "content",
          }),
        ],
        1
      ),
      _c(
        "MuiModal",
        _vm._b(
          {
            attrs: { "data-test-id": "list-cell-delete-alert-by-email-modal" },
            on: {
              "close-modal": function ($event) {
                _vm.isModalOpen = false
              },
            },
            model: {
              value: _vm.isModalOpen,
              callback: function ($$v) {
                _vm.isModalOpen = $$v
              },
              expression: "isModalOpen",
            },
          },
          "MuiModal",
          _vm.modalConfig,
          false
        ),
        [
          _c(
            "div",
            {
              staticClass: "py-3 emobg-font-weight-light emobg-color-ink-light",
              attrs: {
                slot: "body",
                "data-test-id": "list-cell-delete-feedback",
              },
              slot: "body",
            },
            [
              _vm._v(
                " Are you sure you want to remove full backoffice access for: "
              ),
              _c("p", { staticClass: "pt-3" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.result.user.first_name) +
                    " " +
                    _vm._s(_vm.result.user.last_name) +
                    " - "
                ),
              ]),
              _c("p", { staticClass: "emobg-color-ink" }, [
                _vm._v(" " + _vm._s(_vm.result.user.email) + " "),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-end p-3",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "ui-button",
                {
                  staticClass: "emobg-color-white-contrast mr-2",
                  attrs: {
                    "data-test-id": "list-cell-modal-cancel",
                    color: _vm.GRAYSCALE.white,
                  },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isModalOpen = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "ui-button",
                {
                  attrs: {
                    "data-test-id": "list-cell-modal-confirm",
                    color: _vm.COLORS.danger,
                  },
                  on: {
                    clickbutton: function ($event) {
                      return _vm.confirmAgentRemoval()
                    },
                  },
                },
                [_vm._v(" Remove access ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }