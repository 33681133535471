var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { attrs: { "data-test-id": "item_list" } },
      [
        _vm._v(" " + _vm._s(_vm.itemsList) + " "),
        _vm.additionalInfo
          ? _c(
              "ui-tooltip",
              { key: _vm.itemsString, attrs: { tooltip: _vm.itemsString } },
              [_vm._v(" " + _vm._s(_vm.additionalInfo) + " ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }